.block-compared-cards-wrapper {
    border-radius: 5px;
    border: solid thin #d9d9d9;
    background-color: #fafafa;
    margin-bottom: 1.5%;
}
	.block-compared-cards-wrapper .block-compared-cards-header {
		text-align: center;
		margin: 0.4em;
	}

	.block-compared-cards-sceleton {
		margin-top: 2em;
	}

.product-portfolio-cards-block {
    padding: 1%;
    border-radius: 10px;
    border: solid 1px #d9d9d9;
    background-color: #fafafa;
    margin-bottom: 1.5%;
}
	.product-portfolio-cards-block h4{
		color: rgba(0, 0, 0, 0.75);
		text-align: center;
	}
	.product-portfolio-cards-block:hover h4{
		color: rgba(0, 0, 0, 1);
	}
	.product-portfolio-cards-block p{
		color: rgba(0, 0, 0, 0.85);
		padding-top: 0.5em;
	}
	.product-portfolio-cards-block .ant-card{
		margin-top: 1.5%;
	}
		.product-portfolio-cards-block .ant-card .ant-card-body{
			padding-bottom: 0;
		}