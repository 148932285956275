.analog_larger_tag,
.analog_large_tag,
.analog_medium_tag,
.analog_small_tag,
.analog_smaller_tag {
	display: inline-block;
	cursor: pointer;
}

.analog_larger_tag .ant-tag:hover,
.analog_large_tag .ant-tag:hover,
.analog_medium_tag .ant-tag:hover,
.analog_small_tag .ant-tag:hover,
.analog_smaller_tag .ant-tag:hover{
	-webkit-box-shadow: 0px 4px 8px 4px rgba(153, 149, 140, 0.2);
	-moz-box-shadow: 0px 4px 8px 4px rgba(153, 149, 140, 0.2);
	box-shadow: 0px 4px 8px 4px rgba(153, 149, 140, 0.2);
}
	.analog_larger_tag .ant-tag {
		padding: 15px;
		margin: 5px;
		font-size: larger;
	}
	.analog_large_tag .ant-tag {
		padding: 10px;
		margin: 5px;
		font-size: large;
	}
	.analog_medium_tag .ant-tag {
		padding: 10px;
		margin: 5px;
		font-size: medium;
	}
	.analog_small_tag .ant-tag {
		padding: 5px;
		margin: 5px;
		font-size: small;
	}
	.analog_smaller_tag .ant-tag {
		padding: 3px;
		margin: 5px;
		font-size: smaller;
	}

.ant-modal-root .tableInModal tbody tr {
  vertical-align: top;
}

    .ant-modal-body .tableInModal,
    .ant-modal-body .alertInModal,
    .ant-modal-body .regular-grid-tags-collapse {
        margin-top: 2em;
    }
    .ant-modal-body .tank-mixture-component-header {
        margin-top: 1em;
    }

	.analog_table_tag .ant-tag {
		padding: 5px;
		margin: 0px 5px 5px 5px;
		font-size: small;
	}

	.analog_panel_tag .ant-tag {
		padding: 5px;
		margin: 0px 5px 5px 0px;
		font-size: medium;
	}
		