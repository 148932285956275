.selected-params-block {
	margin: 1em 0;
}
	.selected-params-block .selected-params-string {
		font-weight: bold;
	}
.reglaments-panel-block .ant-collapse-content-box h4,
.reglaments-panel-block .ant-collapse-content-box p,
.inactive-reglaments-panel-block .ant-collapse-content-box h4, 
.inactive-reglaments-panel-block .ant-collapse-content-box p {
	margin-left: 2em;
}
.selected-params-block .ant-tag{
	margin-bottom: 0.25em;
}
.inactive-row,
.inactive-reglaments-panel-block .ant-collapse-content-box h4, 
.inactive-reglaments-panel-block .ant-collapse-content-box p {
	color: #d1d1d1; /* Блеклый цвет текста */
	font-style: italic; /* Курсив */
}