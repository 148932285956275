.custom-label {
	display: inline-block;
	width: 1.7em;
	height: 1.7em;
	border-style: solid;
	margin-right: 0.5em;
	opacity: 80%;
}

.custom-circle-label {
	min-width: 1.7em;
	border-radius: 50%;
}
.custom-square-label {
	min-width: 1.7em;
	border-radius: 15%;
}
.custom-rectangular-label {
	width: 2.6em;
	min-width: 2.6em;
	border-radius: 10%;
}

.ant-card:hover .custom-label {
	opacity: 100%;
	transition: 0.75s;
}
.label-letter {
	padding: 0 1px;
}

.custom-acronym-label{
	display: inline-block;
	width: auto;
	font-weight: bold;
	text-align: center;
	border-style: solid;
	margin-right: 0.5em;
	padding: 0.1em;
}
.custom-compared-card .custom-acronym-label{
	margin-bottom: 1em;
}
.custom-brand-acronym-label{
	display: inline-block;
	margin-right: 0.25em;
	padding: 0 0.25em;
	font-weight: bold;
	text-align: center;
}