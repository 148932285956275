.ant-card .pesticide-MOA-group-label-wrapper{
	margin-top: 0.5em;
}
	.ant-card .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block,
	.ant-table-cell .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block,
	.ant-collapse-item .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block{
		display: inline-block;
		border: solid medium rgba(0, 0, 0, 1.0);
		margin-right: 5em;
		margin-bottom: 0.15em;
		font-weight: bold;
		font-size: 75%;
		opacity: 80%;
	}
	.ant-table-cell .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block{
		opacity: 100%;
	}

	.ant-card:hover .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block {
		opacity: 100%;
		transition: 0.75s;
	}
		.ant-card .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block .pesticide-MOA-label,
		.ant-table-cell .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block .pesticide-MOA-label,
		.ant-collapse-item .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block .pesticide-MOA-label{
			color: rgba(255, 255, 255, 1.0);
			border: solid medium rgba(0, 0, 0, 1.0);
			background-color: rgba(0, 0, 0, 1.0);
			text-align: center;
			padding-left: 0.5em;
			padding-right: 0.5em;
			margin-right: 0.2em;
		}
		.ant-card .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block .pesticide-MOA-text,
		.ant-table-cell .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block .pesticide-MOA-text,
		.ant-collapse-item .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block .pesticide-MOA-text{
			padding-left: 0.25em;
			padding-right: 0.25em;
		}
		.ant-card .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block .pesticide-MOA-text-end,
		.ant-table-cell .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block .pesticide-MOA-text-end,
		.ant-collapse-item .pesticide-MOA-group-label-wrapper .pesticide-MOA-group-label-block .pesticide-MOA-text-end{
			padding-left: 0;
			padding-right: 0.25em;
		}
