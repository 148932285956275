@import '~antd/dist/antd.css';
* {margin: 0; padding: 0;}

body {
    font-family: sans-serif;
    font-size: 16px;
}
#root {
    background: rgba(92,159,137,0.03);
    background: linear-gradient(180deg, rgba(92,159,137,0.03) 50%, rgba(255,255,255,1) 100%);
}


/*Extra small devices (portrait phones, less than 576px, xs)*/
@media (max-width: 575.98px) {
    .catalog-page-wrapper,
    .product-portfolio-page-wrapper,
    .find-analogs-page-wrapper{
        margin: 1em;
    }
    .find-analogs-page-wrapper .find-analogs-page-select,
    .find-analogs-page-wrapper .find-analogs-page-select-brand {
        width: 100%;
    }

    .product-portfolio-page-wrapper .product-portfolio-column-header{
        font-size: medium;
    }
    .product-portfolio-page-wrapper .product-portfolio-alert,
    .product-portfolio-page-wrapper .product-portfolio-primary-group-header,
    .product-portfolio-page-wrapper .product-portfolio-page-empty-text {
        margin-top: 2em
    }

    .custom-result-block .ant-result-title {
        font-size: 1.1em;
    }
    .custom-result-block .ant-result-info{
        padding: 0 32px;
    }
    .custom-result-block .ant-result-extra a{
        margin-bottom: 1em;
    }
    .custom-login-form {
        width: 80%;
        margin-left: 10%;
        margin-top: 2em;
    }
}

/*Small devices (landscape phones, 576px and up, sm)*/
@media (min-width: 576px) and (max-width: 767.98px) {
    .catalog-page-wrapper,
    .product-portfolio-page-wrapper,
    .find-analogs-page-wrapper{
        margin: 1em;
    }
    .find-analogs-page-wrapper .find-analogs-page-select,
    .find-analogs-page-wrapper .find-analogs-page-select-brand {
        width: 100%;
    }

    .product-portfolio-page-wrapper .product-portfolio-column-header{
        font-size: large;
    }
    .product-portfolio-page-wrapper .product-portfolio-alert,
    .product-portfolio-page-wrapper .product-portfolio-primary-group-header,
    .product-portfolio-page-wrapper .product-portfolio-page-empty-text {
        margin-top: 2em
    }

    .custom-result-block .ant-result-title {
        font-size: 1.4em;
    }
    .custom-login-form {
        width: 70%;
        margin-left: 15%;
        margin-top: 4em;
    }
}

/*Medium devices (tablets, 768px and up, md)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .catalog-page-wrapper,
    .product-portfolio-page-wrapper,
    .find-analogs-page-wrapper{
        margin: 1em;
    }
    .find-analogs-page-wrapper .find-analogs-page-select,
    .find-analogs-page-wrapper .find-analogs-page-select-brand {
        width: 100%;
    }

    .product-portfolio-page-wrapper .product-portfolio-alert,
    .product-portfolio-page-wrapper .product-portfolio-primary-group-header,
    .product-portfolio-page-wrapper .product-portfolio-page-empty-text {
        margin-top: 3em
    }
    .custom-login-form {
        width: 50%;
        margin-left: 25%;
        margin-top: 4em;
    }
}

/*Large devices (desktops, 992px and up, lg)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    .catalog-page-wrapper,
    .product-portfolio-page-wrapper,
    .find-analogs-page-wrapper{
        margin: 3em;
    }
    .find-analogs-page-wrapper .find-analogs-page-select {
        width: 50%;
    }
    .find-analogs-page-wrapper .find-analogs-page-select-brand{
        width: 35%;
        margin-left: 5%;
    }
    .product-portfolio-page-wrapper .product-portfolio-alert,
    .product-portfolio-page-wrapper .product-portfolio-primary-group-header,
    .product-portfolio-page-wrapper .product-portfolio-page-empty-text {
        margin-top: 4em
    }

    .custom-login-form {
        width: 40%;
        margin-left: 30%;
        margin-top: 5em;
    }
}

/*Extra large devices (large desktops, 1200px and up, xl)*/
@media (min-width: 1199.98px) and (max-width: 1600px) {
    .catalog-page-wrapper,
    .product-portfolio-page-wrapper,
    .find-analogs-page-wrapper{
        margin: 3em;
    }
    .find-analogs-page-wrapper .find-analogs-page-select {
        width: 33%;
    }
    .find-analogs-page-wrapper .find-analogs-page-select-brand{
        width: 25%;
        margin-left: 5%;
    }
    .product-portfolio-page-wrapper .product-portfolio-alert,
    .product-portfolio-page-wrapper .product-portfolio-primary-group-header,
    .product-portfolio-page-wrapper .product-portfolio-page-empty-text {
        margin-top: 4em
    }
    .custom-login-form {
        width: 30%;
        margin-left: 35%;
        margin-top: 5em;
    }
}

/*Super large devices (large desktops, 1600px and up, xxl)*/
@media (min-width: 1600px) {
    .catalog-page-wrapper,
    .product-portfolio-page-wrapper,
    .find-analogs-page-wrapper{
        margin: 4em;
    }
    .find-analogs-page-wrapper .find-analogs-page-select {
        width: 25%;
    }
    .find-analogs-page-wrapper .find-analogs-page-select-brand{
        width: 20%;
        margin-left: 5%;
    }
    .product-portfolio-page-wrapper .product-portfolio-alert,
    .product-portfolio-page-wrapper .product-portfolio-primary-group-header,
    .product-portfolio-page-wrapper .product-portfolio-page-empty-text {
        margin-top: 4em
    }
    .catalog-page-wrapper .filters-block{
        margin-top: 1em;
    }
    .custom-login-form {
        width: 30%;
        margin-left: 35%;
        margin-top: 5em;
    }
}

h1.custom-page-header{
    font-size: x-large;
}
.product-portfolio-page-wrapper .product-portfolio-page-panel {
    margin-bottom: 1em;
}

.product-portfolio-page-wrapper .product-portfolio-column-header {
    height: 100%;
    color: rgba(18,39,61, 1.0);
    text-align: center;
}
.product-portfolio-page-wrapper .product-portfolio-select {
    width: 100%;
    margin-top: 5;
    margin-bottom: 20;
}
.product-portfolio-page-wrapper .product-portfolio-primary-group-header {
    text-align: center;
}
.product-portfolio-page-wrapper .product-portfolio-page-empty-text {
    font-size: 130%;
    text-align: center;
}

.find-analogs-page-wrapper .find-analogs-page-select {
    margin-bottom: 1em;
}
.find-analogs-page-wrapper .find-analogs-page-select-brand {
    margin-bottom: 2em;
}

.catalog-page-wrapper .ant-page-header,
.product-portfolio-page-wrapper .ant-page-header,
.find-analogs-page-wrapper .ant-page-header{
    margin-bottom: 1em;
}

.content {
    padding:20px
}
.content p {
    margin:10px 0
}

button {
    font-size: 18px;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 6px;
    background-color: #eee;
    border:solid 1px #aaa;
    outline: none;
    margin: 5px 5px 5px 0;
}
button:active {
    background-color: #333;
    color: #fff;
}

.form input, form input {
    font-size: 16px;
    padding: 10px;
    border:solid 1px #aaa;
    outline: none;
    margin: 15px 0;
    border-radius: 6px;
    display: block;
}

.error {
    margin: 10px 0;
    color: crimson;
}

.request-info {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}
.request-info span {
    margin: 5px 0;
}
.page-wrapper{
    display: inline-flex;
}
    div.content{
        display: flex;
    }
        div.content div.filters{
            flex-grow: 2;
            max-width: 100%;
            width: 25%;
        }
        div.content div.results{
            flex-grow: 2;
            max-width: 100%;
            width: 75%;
        }
.site-page-header {
        border: 1px solid rgb(235, 237, 240);
}
.catalog-page-wrapper .site-page-header {
    margin-bottom: 1em;
}

.ant-radio-group-custom-wrapper{
    margin-top: 1em;
}
    .ant-radio-group-custom-wrapper .ant-radio-group-custom-title{
        margin-right: 2em;
        font-weight: 500;
    }

    .ant-radio-group-custom-wrapper .ant-radio-group-custom-inline-title{
        margin-right: 2em;
        font-weight: 500;
        display: inline-block;
    }
    .ant-radio-group-custom-wrapper .ant-radio-group-custom-block-title{
        margin-left: 7px;
        font-weight: 500;
        display: block;
    }

    .ant-radio-group-custom-wrapper .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
    .ant-radio-group-custom-wrapper .ant-radio-button-wrapper,
    .ant-radio-group-custom-wrapper .ant-radio-button-wrapper-checked,
    .ant-radio-group-custom-wrapper .ant-radio-button,
    .ant-radio-group-custom-wrapper .ant-radio-button-checked,
    .ant-radio-group-custom-wrapper .ant-radio-button-wrapper.ant-radio-button-wrapper:first-child,
    .ant-radio-group-custom-wrapper .ant-radio-button-inner{
        border: none;
    }
    .ant-radio-group-custom-wrapper .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled)::before,
    .ant-radio-group-custom-wrapper .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
        display: none;
    }
    .ant-radio-group-custom-wrapper .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
        box-shadow: 0 0 0 0px;
    }
    .ant-radio-group-custom-wrapper .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-radio-group-custom-wrapper .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        cursor: default;
    }

    .custom-login-form .custom-login-form-input{
        margin-bottom: 2em;
        height: 5em;
    }

    .custom-login-form .custom-login-form-button{
        margin-bottom: 1em;
        height: 4em;
    }
    .custom-login-form .custom-login-form-alert{
        margin-bottom: 1em;
    }

    .catalog-page-filters-block .catalog-page-filters-panel{
        margin-top: 1em;
    }

