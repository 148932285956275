
.search-block-wrapper .ant-input-search-button,
.search-block-wrapper .ant-btn-primary
.search-block-wrapper .ant-input-search-button:hover {
	background: rgba(18,39,61, 1.0);
	background: linear-gradient(90deg, rgba(18,39,61,1) 0%, rgba(15,62,47,1)  100%);
	border-color: rgba(18,39,61, 1.0);
}
.search-block-wrapper .ant-input-search-button:hover,
.search-block-wrapper .ant-btn-primary:hover, 
.search-block-wrapper .ant-btn-primary:focus {
	background: rgba(18,39,61, 0.8);
	background: linear-gradient(90deg, rgba(15,62,47,0.9) 0%, rgba(18,39,61,1)  100%);
	border-color: rgba(18,39,61, 1.0);
}

.search-block-wrapper .ant-input-search-button:disabled,
.search-block-wrapper .ant-btn-primary:disabled, 
.search-block-wrapper .ant-btn-primary:disabled {
	background: rgba(18,39,61, 0.8);
	background: linear-gradient(90deg, rgba(15,62,47,0.4) 0%, rgba(18,39,61,0.4)  100%);
	border-color: rgba(18,39,61, 1.0);
}

