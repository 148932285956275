/*#B3832F - коричневый, #a2cac9 - светло-голубой, #8C5896 - сиреневый, #3F642C - болотный, #489A6C - светло-зелёный, rgba(103,104,171,1) - 2022*/
.main-menu {
    padding: 10px 20px;
    display: flex;
    background-color: #12273D;/*#a2cac9  papayawhip tomato dodgerblue rebeccapurple*/
    background: linear-gradient(90deg, rgba(18,39,61,1) 0%, rgba(15,62,47,1) 50%, rgba(61,39,7,1) 100%);
    align-items: center;
    height: 60px;
}

.main-menu li {
    list-style-type: none;
    margin-right: 20px;
}

.main-menu li.logo {
    color: Honeydew;
    font-size: 18px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.main-menu li a {
    text-decoration: none;
    color: Honeydew;/*#5C9F89*/
    font-size: 18px;
    padding: 10px 0;
    transition: .5s;
}

.main-menu li a:hover {
    border-bottom: solid 3px Honeydew;
    transition: .3s;
}

.main-menu li a.active {
    text-decoration: none;
    color: Honeydew;
    border-bottom: solid 3px Honeydew;
}

.main-menu li.right-top {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    border: none;
    margin: 0;
}
.main-menu li.right-top a {
    border:none
}

.main-menu li.right-top .drawer-menu-button {
    color: rgba(255, 255, 255, 1.0);
}

.drawer-main-menu {
    font-size: 2em;
}

    .drawer-main-menu li {
        list-style-type: none;
        margin: 1em 0.25em;
    }

        .drawer-main-menu li a {
            text-decoration: none;
            color: #000;
            transition: .5s;
        }

        .drawer-main-menu li a:hover {
            border-bottom: solid 3px Honeydew;
            transition: .3s;
        }

        .drawer-main-menu li a.active {
            text-decoration: none;
            color: #000;
            border-bottom: solid 3px #1890FF;
        }
        .drawer-main-menu .drawer-main-menu-user-block {
            font-size: 0.75em;
            /*padding-left: 10px;*/
            text-align: center;
        }

/*Extra small devices (portrait phones, less than 576px, xs)*/
@media (max-width: 575.98px) {
    .main-menu li.logo, .main-menu li a {
        font-size: 1.3em;
    }
    .drawer-main-menu {
        font-size: 1.4em;
    }
}

/*Small devices (landscape phones, 576px and up, sm)*/
@media (min-width: 576px) and (max-width: 767.98px) {
    .main-menu li.logo, .main-menu li a {
        font-size: 1.3em;
    }
}

/*Medium devices (tablets, 768px and up, md)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .main-menu li.logo, .main-menu li a {
        font-size: 1.3em;
    }
}

/*Large devices (desktops, 992px and up, lg)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    .main-menu li.logo, .main-menu li a {
        font-size: 1.3em;
    }
}

/*Extra large devices (large desktops, 1200px and up, xl)*/
@media (min-width: 1199.98px) and (max-width: 1600px) {
    .main-menu li.logo, .main-menu li a {
        font-size: 1.3em;
    }
}

/*Super large devices (large desktops, 1600px and up, xxl)*/
@media (min-width: 1600px) {
    .main-menu {
        height: 80px;
    }
        .main-menu li.logo, .main-menu li a {
            font-size: 2em;
        }

}
