.reglaments-panel-header-wrapper {
	
}
.reglaments-panel-header-wrapper .reglaments-panel-header {
	
}
.reglaments-panel-header-wrapper .inactive-reglaments-panel-header h3,
.reglaments-panel-header-wrapper .inactive-reglaments-panel-header p {
	color: #d1d1d1; /* Блеклый цвет текста */
  	font-style: italic; /* Курсив */
}
